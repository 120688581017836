import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '../views/Home.vue'
import ClubApplication from '../views/ClubApplication.vue'
import Minigame from '../views/Minigame.vue'
import ViewClubApplications from '@/views/ViewClubApplications.vue'
import AllClubs from '@/views/AllClubs.vue'
import Dodge from '@/views/Dodge.vue'
import ViewTicket from '@/views/ViewTicket.vue'
import TicketImport from '@/views/TicketImport.vue'
import NotFound from '@/views/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/minigame',
    name: 'Minigame',
    component: Minigame
  }, 
  {
    path: '/dodge',
    name: 'Dodge',
    component: Dodge
  },
  {
    path: '/-/:ticketNumber',
    name: 'View Ticket',
    component: ViewTicket
  },
  {
    path: '/manage/ticketimport',
    name: 'Import Ticket',
    component: TicketImport
  },
  {
    path: '/:pathMatch(.*)',
    name: '404 not found page', 
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
