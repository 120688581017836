
import { Options, Vue } from 'vue-class-component'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue'
import Arrow from '../components/Arrow.vue'
import { SmoothScrollOptions } from 'vue3-smooth-scroll'
import { inject } from 'vue'

@Options({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
    Arrow
  },
  setup () {
    const smoothScroll = inject('smoothScroll') as (arg: SmoothScrollOptions) => void
    const scrollToElmWithId = (id: string) => {
      smoothScroll({
        scrollTo: document.getElementById(id) as Element,
        duration: 500
      })
    }
    return {
      scrollToElmWithId
    }
  }
})
export default class Header extends Vue {
  smoothScroll = inject('smoothScroll') as (arg: SmoothScrollOptions) => void
  scrollToElmWithId = (id: string) => {
    this.smoothScroll({
      scrollTo: document.getElementById(id) as Element,
      duration: 500
    })
  }
}
