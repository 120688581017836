
import Rectangle from '@/ts_modules/dinosaur/Rectangle'
import { defineComponent } from 'vue'

import Renderer, { SpriteAnimation, Sprite } from '../ts_modules/dinosaur/Renderer'
import Vector from '../ts_modules/dinosaur/Vector'

export default defineComponent({
  setup () {
    return {
      id: `under-construction-${Math.random()}`
    }
  },
  mounted () {
    const animationDiv = document.getElementById(this.id as string) as HTMLDivElement
    const sunflowerSpriteSheet = new Image()
    sunflowerSpriteSheet.src = 'https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img/minigame/sunflower-concat.png'

    const sunflowerAnimation = new SpriteAnimation(sunflowerSpriteSheet, {
      frames: 60,
      cols: 60,
      singleSpriteSize: new Vector(256, 256)
    })
    const renderer = new Renderer(animationDiv, {
      sunflower: new Sprite(sunflowerAnimation, Rectangle.fromNumbers(0, 0, 256, 256))
    }, 1, false)
    const render = () => {
      renderer.render()
      requestAnimationFrame(render)
    }
    requestAnimationFrame(render)
  }
})
