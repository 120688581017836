<template>
  <div class="navbar-wrapper">
    <ul class="navbar">
      <router-link to="/"><li class="nav-item">首页</li></router-link>
    </ul>
  </div>
</template>
<script>
import { Vue } from 'vue-class-component'
export default class Navbar extends Vue {}
</script>
<style scoped>
@media(max-width: 500px) {
  .navbar {
    flex-direction: column;
  }
}
.navbar-wrapper {
  width: 100%;
  margin: auto;
  padding: 0;
  background-color: brown;
}
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
}
.nav-item {
  display: block;
  margin: 0 5px;
  padding: 10px 15px;
  background-color: brown;
  color: white;
  font-weight: bold;
  transition: all 0.3s;
}
.nav-item:hover {
  cursor: pointer;
  user-select: none;
  background-color: rgb(219, 43, 43);
}
.nav-item a {
  color: white;
}
</style>
